import React from "react";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";
import { getFlex, palette } from "../../styles/styles";
import {useI18next, useTranslation} from "gatsby-plugin-react-i18next";

const First = (props) => {
  const { className } = props;
  const { t } = useTranslation("autoHunt");
  const { language } = useI18next();
  return (
    <section className={className}>
      <h2>{t("firstTitle")}</h2>
      <p className="desc">{t("firstDesc")}</p>
      {language === "es" ?
        <StaticImage
          className="firsImg"
          src="../../images/carousel/autoHuntEn.png"
          alt=""
        /> :
        <StaticImage
          className="firsImg"
          src="../../images/carousel/autoHunt.png"
          alt=""
        />
      }
      <hr />
    </section>
  );
};

export default styled(First)`
  padding: 4% 16%;
  @media (max-height: 899px) {
    height: 100vh;
  }
  @media (min-height: 900px) {
    height: fit-content;
  }
  ${getFlex("column", "sb", "ct")};
  row-gap: 2rem;
  @media(max-width: 768px){
    padding: 10%;
  }
  @media(max-width: 400px){
    height: fit-content;
  }
  h2 {
    font-size: 4.8rem;
    text-align: center;
    width: 90%;
    @media(max-width: 600px){
        width: 100%;
    }
  }
  .desc {
    font-family: SofiaProLightAz, sans-serif;
    font-size: 2rem;
    line-height: 3.2rem;
    text-align: center;
    width: 80%;
    margin-bottom: 4rem;
    @media(max-width: 600px){
      width: 100%;
    }
  }
  .firsImg {
    width: 90%;
    max-width: 1000px;
    @media(max-width: 600px){
      width: 100%;
    }
  }
  hr {
    height: 2px;
    width: 100%;
    color: ${palette.grey};
  }
`;
