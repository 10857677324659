import React from "react";
import styled from "styled-components";
import Layout from "../comps/reusable/Layout";
import HeroSection from "../comps/reusable/HeroSection";
import First from "../comps/auto-hunt/fisrt";
import Multi from "../comps/auto-hunt/multi";
import Final from "../comps/auto-hunt/Final";
import {graphql} from "gatsby";

const PreScreening = () => {
  return (
    <Layout>
      <HeroSection
        data={{
          imageName: "heroHunt.png",
          hasButtons: true,
        }}
        nameSpace="autoHunt"
      />
      <First />
      <Multi
        data={{
          type: 0,
          imageName: "exec0.png",
          red: "multiFirstRed",
          h3: "multiFirstTitle",
          p: "multiFirstDesc",
        }}
      />
      <Multi
        data={{
          type: 1,
          imageName: "autohunt2.png",
          red: "multiSecondRed",
          h3: "multiSecondTitle",
          p: "multiSecondDesc", }}
      />
      <Multi
        data={{
          type: 0,
          imageName: "autohunt3.png",
          red: "multiThirdRed",
          h3: "multiThirdTitle",
          p: "multiThirdDesc", }}
      />
      <Final />
    </Layout>
  );
};

export default styled(PreScreening)``;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
