import styled from "styled-components";
import React from "react";
import { getFlex, linkRouge, palette } from "../../styles/styles";
import Image from "../../ImageQuerys/Product1Images";
import { useTranslation } from "gatsby-plugin-react-i18next";

const Multi = (props) => {
  const { className, data } = props;
  const { t } = useTranslation("autoHunt");

  return (
    <section
      style={{
        backgroundColor: `${data.type ? palette.blue : "#FCFCFC"}`,
      }}
      className={className}
    >
      {data.type ? (
        <>
          <div className="secondSecond">
            <p className="red">{t(data.red)}</p>
            <h3 style={{ color: `${palette.whiteWhite}` }}>{t(data.h3)}</h3>
            <p style={{ color: `${palette.whiteWhite}` }}>{t(data.p)}</p>
            <div className="final">
              <a
                href="https://ats.lapieza.io/authorize-me"
                target="_blank"
                rel="noreferrer"
                className="link"
              >
                {t("finalButton")}
              </a>
              <p>{t("finalFinal")}</p>
            </div>
          </div>
          <Image className="image" imageName={data.imageName} />
        </>
      ) : (
        <>
          <Image className="image imageLeft" imageName={data.imageName} />
          <div className="secondSecond">
            <p className="red">{t(data.red)}</p>
            <h3>{t(data.h3)}</h3>
            <p>{t(data.p)}</p>
          </div>
          <Image className="mobileImg" imageName={data.imageName} />
        </>
      )}
    </section>
  );
};

export default styled(Multi)`
  padding: 8% 16%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  @media (max-width: 1024px) {
    padding: 6% 10%;
  }
  .image {
    max-width: 600px;
    width: 80%;
    margin-left: 4rem;
  }
  .mobileImg{
    display: none;
  }
  .secondSecond {
    height: 90%;
    display: grid;
    grid-template-rows: auto;
    align-items: center;
    justify-items: flex-start;
    grid-row-gap: 2rem;
    @media (max-width: 600px) {
      align-items: center;
      justify-items: center;
      & > * {
        text-align: center;
        width: 80%;
      }
    }
    .red {
      color: ${palette.red};
      font-size: 2.4rem;
      margin-bottom: -2rem;
    }
    h3 {
      font-size: 3.4rem;
    }
    p {
      font-size: 1.6rem;
      font-family: SofiaProLightAz, sans-serif;
      line-height: 3.2rem;
      margin-bottom: 2rem;
    }
    .final {
      ${getFlex("row", "st", "ct")};
      @media (max-width: 600px) {
        flex-direction: column;
        grid-row-gap: 1rem;
        justify-content: center;
        p{
          margin: 0!important;
        }
      }
      .link {
        ${linkRouge};
        min-width: fit-content;
      }
      p {
        color: ${palette.whiteWhite};
        font-size: 2rem;
        margin: 0 0 0 2rem;
        min-width: fit-content;
      }
    }
  }
  @media (max-width: 600px){
    padding: 10% 8%;
    height: fit-content;
    grid-template-columns: 1fr;
    grid-row-gap: 4rem;
    justify-items: center;
    .imageLeft{
      display: none;
    }
    .mobileImg, image {
      width: 80%;
      display: flex;
    }
  }
`;
